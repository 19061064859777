.col_w1000 {
    width: 1000px;
    margin: 0 auto;
}
.h_sub_nav {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #e6e6e6;
}
.h_sub_nav ul {
    width: 700px;
    float: left;
}
.h_sub_nav li {
    float: left;
}

.h_sub_nav li a {
    color: #565656;
    line-height: 36px;
    font-size: 14px;
    margin-right: 25px;
}
.h_sub_nav .share {
    float: right;
    width: 300px;
    height: 36px;
    cursor: pointer;
    z-index: 11;
}
.h_sub_nav .e_nav {
    float: left;
    position: relative;
    z-index: 11;
}
.h_sub_nav .e_icon {
    margin-right: 15px;
}
.h_sub_nav .qrcode {
    display: block;
    position: absolute;
    top: 35px;
    z-index: 11;
    zoom: 1;
}
.h_sub_nav .kehu {
    left: -92px;
    width: 266px;
    height: 495px;
    background: url(./e1.png) no-repeat left top;
}
.h_sub_nav .jiaju {
    left: -19px;
}
.h_sub_nav .sheji {
    left: -19px;
}
