.focus_img {
    width: 710px;
    height: 345px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
}
.focus_img .swiper {
    float: left;
    width: 710px;
    position: relative;
}
.focus_img .pic-swiper {
    width: 4970px;
}
.focus_img .swiper img {
    width: 710px;
    height: 345px;
}
.info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #000;
    opacity: 0.8;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    overflow: hidden;
    text-align: center;
}
.focus_img .info .current {
    display: inline-block;
}
.focus_img .f_word {
    display: inline;
    height: 44px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    line-height: 44px;
}
/*.focus_img .current {
	display: block;
}*/

.focus_img {
    & [ifeng_ui_pc_sliders-dots] {
        position: absolute;
        left: 0;
        _left: 0;
        bottom: 60px;
        width: 100%;
        text-align: right;
        cursor: pointer;

        & .dot {
            display: inline-block;
            width: 16px;
            height: 16px;
            padding-right: 2px;
            background: url(./point.png) no-repeat 0 0;
            line-height: 18px;
        }

        & .dot_current {
            display: inline-block;
            width: 16px;
            height: 16px;
            padding-right: 2px;
            background: url(./point-current.png) no-repeat 0 0;
            line-height: 18px;
        }
    }

    & [ifeng_ui_pc_sliders-arrows_next] {
        right: 9px;
        bottom: 10px;
        z-index: 11;
        top: auto;
        width: 11px;
        height: 21px;
        z-index: 99999;
        background: rgba(0, 0, 0, 0.5) url(./bt-right.gif) no-repeat center center;
        background-size: 100% 100%;
    }

    & [ifeng_ui_pc_sliders-arrows_pre] {
        left: 9px;
        bottom: 10px;
        z-index: 11;
        top: auto;
        width: 11px;
        height: 21px;
        z-index: 99999;
        background: rgba(0, 0, 0, 0.5) url(./bt-left.gif) no-repeat center center;
        background-size: 100% 100%;
    }
}

.focus_img .prevSlide {
    /*display:none;*/
    position: absolute;
    left: 9px;
    bottom: 10px;
    z-index: 11;
    cursor: pointer;
}
.focus_img .nextSlide {
    /*display:none;*/
    position: absolute;
    right: 9px;
    bottom: 10px;
    z-index: 11;
    cursor: pointer;
}
