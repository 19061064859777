.homenews {
    margin-top: 28px;
    width: 100%;
}

.homenews a:hover {
    color: #f64949;
    text-decoration: none;
}

.headbox:hover {
    background: #f1f1f1;
    & .mod_list {
        background: #f1f1f1;
    }
}

.headline {
    padding-left: 10px;
    font-size: 22px;
    font-weight: bold;
    color: #000;
    border-left: 5px solid #f64949;
    line-height: 24px;
}

/*列表模块*/
.mod_list {
    composes: clearfix from global;
    height: 140px;
    padding: 19px 0;
    position: relative;
    background: #fff;
}

.border_top {
    composes: mod_list;
    border-top: 1px dashed #d5d5d5;
}

.border_color {
    composes: mod_list;
    border-top: 1px dashed #d5d5d5;
    background: #f1f1f1;
}

.border_color:hover {
    background: #f1f1f1;
}

.border_first_color {
    composes: mod_list;
    background: #f1f1f1;
}
.mod_list:hover {
    background: #f1f1f1;
}
.mod_list .list_img {
    float: left;
}
.mod_list .list_info {
    float: right;
    width: 459px;
    height: 140px;
    position: relative;
}
.mod_list .title a {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.mod_list .title a:hover {
    color: #f64949;
}
.mod_list .phone {
    display: none;
}
.detail {
    padding-top: 19px;
    color: #666;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.diff {
    composes: detail;
    padding-top: 0;
}
.box {
    height: 20px;
    position: absolute;
    bottom: 19px;
    left: 251px;
    overflow: hidden;
    _bottom: 22px;
}
.mod_list .box .logo {
    display: inline-block;
    height: 20px;
    width: 66px;
    background: #c3c3c3;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    _line-height: 22px;
    text-align: center;
}
.mod_list .box .logo:hover {
    background: #f64949;
    color: #fff;
}
.mod_list .box .time {
    padding-left: 0px;
    color: #a3a3a3;
    font-size: 12px;
}
.ad-banner-diff {
    height: 140px;
    margin-top: 15px;
}
.mod_container_list {
    margin-top: 17px;
}
.mod_container_list a:hover {
    color: #f64949;
    text-decoration: none;
}
.mod_container_list .title a {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.mod_container_list .title a:hover {
    color: #f64949;
}
.mod_container_list .list {
    width: 719px;
    margin-top: 17px;
    padding-bottom: 19px;
}
.mod_container_list .list li {
    float: left;
    height: 140px;
    padding-right: 9px;
}
.box-left .more {
    display: block;
    width: 100%;
    height: 24px;
    background: #f1f1f1;
    text-align: center;
    line-height: 24px;
    color: #848484;
    font-size: 13px;
}
.box_left .more:hover {
    color: #fff;
    background: #f64949;
}
.ad_banner {
    margin-top: 19px;
    height: 140px;
}
