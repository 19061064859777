.carT_foot {
    margin-top: 60px;
}
/*.col_wbf {*/
/*width: 100%;*/
/*margin: 0 auto;*/
/*background: #f5f5f5 url(./bg_b.gif) repeat-x left top;*/
/*}*/
.col_w1000 {
    width: 1000px;
    margin: 0 auto;
}
.space01 {
    height: 26px;
    overflow: hidden;
}
.bottom1Ad {
    text-align: center;
}
