.mod_lm {
    width: 100%;
    /* height: 266px; */
    padding-top: 30px;
}

.differ {
    composes: mod_lm;
    height: auto;
}

.mod_house {
    width: 100%;
}
