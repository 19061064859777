.mod_lm {
    width: 100%;
    height: auto;
    padding-top: 30px;
}

.mod_lm_title {
    composes: clearfix from global;
    height: 30px;
    background: url(./vice_title_line.png) repeat-x bottom left;
    margin-bottom: 10px;
}

.lt_left {
    float: left;
}

.title {
    padding-left: 10px;
    background: url(./title_point.jpg) no-repeat left center;
    color: #f64444;
    font-size: 18px;
    line-height: 22px;
    font-family: arial, 'Microsoft YaHei';
    font-weight: bold;
}

.mod_phb li span {
    font-family: arial, 'Microsoft YaHei';
    font-size: 13px;
    color: #e64543;
    margin-right: 5px;
    font-weight: bold;
    line-height: 24px;
    float: left;
}

.mod_phb li a {
    width: 240px;
    color: #9e9e9e;
    font-size: 13px;
    font-family: arial, 'Microsoft YaHei';
    line-height: 24px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
}

.mod_phb li a:hover {
    color: #e64543;
}
