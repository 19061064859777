.mod_lm {
    width: 100%;
    /* height: 266px; */
    padding-top: 30px;
}

.diff {
    composes: mod_lm;
    padding-top: 0px;
}

.mod_lm_title {
    composes: clearfix from global;
    height: 30px;
    background: url(./vice_title_line.png) repeat-x bottom left;
    margin-bottom: 10px;
}

.lt_right {
    composes: clearfix from global;
}

.lm_link li {
    padding-top: 1px;
}

.lm_pic {
    width: 260px;
    height: 160px;
    position: relative;
}

.lm_pic img {
    display: block;
}

.lm_txt {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 260px;
    height: 30px;
    background: url(./lm_txt_bg.png) no-repeat;
    z-index: 3;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: #fff !important;
    font-family: arial, 'Microsoft YaHei';
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.lm_link a {
    display: block;
    background-color: #eaeaea;
    height: 32px;
    width: 260px;
    font-family: arial, 'Microsoft YaHei';
    font-size: 13px;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    color: #6a6a6a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.lm_link a:hover {
    background-color: #e64543;
    height: 32px;
    width: 260px;
    color: #fff;
}

.Ad {
    float: right;
}
