.col_box {
    position: relative;
    background: #313131;
    width: 100%;
    margin: 0 auto;
    height: 39px;
}

.col_navlist {
    width: 1000px;
    margin: 0 auto;
    height: 39px;
    & ul {
        /* background: url(./line05.jpg) left top no-repeat; */
        height: 39px;
        overflow: hidden;
        & li {
            font-size: 14px;
            height: 39px;
            line-height: 39px;
            /* background: url(./line05.jpg) right top no-repeat; */
            border-right: 1px solid #5c5c5c;
            padding: 0 12px 0 12px;
            float: left;
            & a {
                color: #fff;
            }
        }
        & .pa {
            /* padding: 0 35px; */
            border-left: 1px solid #5c5c5c;
            & a {
                color: #00cd74;
            }
        }

        & a:hover {
            color: #00cd74;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

/*导航*/

.box_nav {
    margin-top: 11px;
}
.nav_main {
    width: 100%;
    height: 88px;
    background: #e64543;
    overflow: hidden;
    & .col_w1000 {
        width: 1000px;
        margin: 0 auto;
        & .nav_bs_img {
            float: left;
            width: 47px;
            height: 88px;
            margin-right: 20px;
            _margin-right: 10px;
        }
    }
}

.nav_bs_img {
    float: left;
    width: 47px;
    height: 88px;
    margin-right: 20px;
    _margin-right: 10px;
}

.differ {
    margin-left: 26px;
}

.zhuangxiu {
    composes: nav_bs_img;
    composes: differ;
    background: url(./nav_pic01.png) no-repeat left top;
}

.shenghuo {
    composes: nav_bs_img;
    background: url(./nav_pic02.png) no-repeat left top;
}
.sheji {
    composes: nav_bs_img;
    background: url(./nav_pic03.png) no-repeat left top;
}
.cehua {
    composes: nav_bs_img;
    background: url(./nav_pic04.png) no-repeat left top;
}

.nav_main ul {
    float: left;
    width: 175px;
    padding-top: 18px;
}
.nav_main ul li {
    float: left;
    width: 72px;
    margin-right: 7px;
}
.nav_main ul a {
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 26px;
}
