.home_main {
    composes: clearfix from global;
    width: 1000px;
    margin: 29px auto 0;
}

.w1000 {
    width: 1000px;
    margin: 0 auto;
}

.left_main {
    float: left;
    width: 710px;
    overflow: hidden;
}

.right_main {
    float: right;
    width: 260px;
    position: relative;
}
