.more {
    display: block;
    width: 100%;
    height: 24px;
    background: #f1f1f1;
    text-align: center;
    line-height: 24px;
    color: #848484;
    font-size: 13px;
}

.more:hover {
    color: #fff;
    background: #f64949;
}
