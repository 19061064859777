.eight_img {
    width: 1000px;
    margin: 0 auto;
}

.box_list {
    width: 1032px;
    padding-top: 23px;
    _margin-left: 54px;
}
.box_list:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.box_list li {
    width: 230px;
    height: 140px;
    float: left;
    margin: 0 26px 20px 0;
    position: relative;
}
.box_list img {
    display: block;
}
.box_list .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 230px;
    background: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    -moz-opacity: 0.6;
}
.box_list .info {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 220px;
    padding: 0 5px;
    color: #fff;
    line-height: 36px;
    text-align: center;
    font-size: 13px;
    z-index: 99;
    zoom: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
