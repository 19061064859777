/*选材中心*/
.mod_house {
    width: 100%;
}
.mod_house_box {
    padding-top: 5px;
    background: url(./p_1_8.png) repeat-x left bottom;
}
.mod_house_box .item {
    padding: 6px 12px;
}
.mod_house_box .item span {
    padding: 0 5px;
    _padding: 0 3px;
}
.mod_house_box .title {
    height: 30px;
    font: normal 16px/26px 'Microsoft YaHei';
    color: #000;
}
.mod_house_box a {
    font: normal 12px/20px '';
    color: #000;
}
.mod_house_box .item a:hover {
    color: #f54343;
}
.mod_house_box .item .diff {
    font: normal 12px/20px '';
    color: #b51d23;
}
.mod_house_box .current {
    display: block;
}
/* .mod_house_box .scroll ul {
	width:192px;
	height:94px;
	padding:10px 34px;
} */
.mod_house_box .scroll li {
    display: none;
}
.mod_house_box .scroll .current {
    display: block;
}
.scroll a {
    display: block;
    float: left;
    width: 93px;
    height: 46px;
    margin: 1px 1px 0 0;
    overflow: hidden;
}
.mod_house_box .next a {
    width: 24px;
    height: 113px;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0;
    background: url(./p_1_32.png) no-repeat;
}
.mod_house_box .prev a {
    width: 24px;
    height: 113px;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    background: url(./p_1_33.png) no-repeat;
}
.mod_house_box .next a:hover {
    width: 24px;
    height: 113px;
    display: block;
    cursor: pointer;
    background: url(./p_1_35.png) no-repeat;
}
.mod_house_box .prev a:hover {
    width: 24px;
    height: 113px;
    display: block;
    cursor: pointer;
    background: url(./p_1_34.png) no-repeat;
}

.mod_house_box:hover {
    & .scroll {
        display: block;
    }
}

.scroll {
    display: block;
    width: 260px;
    height: 113px;
    position: relative;
    background: #e8e8e8;

    & [ifeng_ui_pc_sliders-arrows_next] {
        width: 24px;
        height: 113px;
        display: block;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0;
        background: url(./p_1_33.png) no-repeat;
        margin-top: 0;
    }

    & [ifeng_ui_pc_sliders-arrows_pre] {
        width: 24px;
        height: 113px;
        display: block;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0;
        background: url(./p_1_32.png) no-repeat;
        margin-top: 0;
    }
}
